import React, { Component } from 'react';
import Moment from 'react-moment';
import { CopyOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { API } from '../IVesta';
import '../App.css';
import 'antd/dist/antd.css';

interface IProps {
    id: string,
    name?: string
    issuedOn?: Date | null
    expiresOn?: string | null
    canUse?: boolean
    canclick?: boolean
    invalid?: boolean
    premium: boolean
}


interface IState {

}


export class QRCode extends Component<IProps, IState>
{

    constructor(props: any) {
        super(props);

        this.state = {

        };

        this.openWin = this.openWin.bind(this);
    }

    copyToClipboard(toCopy: string) {
        navigator.clipboard.writeText(toCopy);

        notification.open({
            message: 'Clip Board',
            description:
                'You certificate url has been copied to the clip board',
            placement: 'topRight'

        });

    };

    openWin(url: string) {
        if(!this.props.canclick ?? false) return
        window.open(url);
    }

    render() {
        const localHost = window.location.href.toLocaleLowerCase().indexOf("localhost") > 0;
        const canUse = this.props.canUse ?? false;
        const certCopyUrl = API + `/certificate/stamp/${this.props.id}`;
        const invalid = this.props.invalid ?? false;
        const certVerifyUrl = !localHost 
        ? `https://staging-venus.mysasure.com/Verify/${this.props.id}`
        : `http://localhost:3000/Verify/${this.props.id}`
        const certcontent = this.props?.issuedOn || invalid
            ? <div className="qr-image" onClick={() => this.openWin(certVerifyUrl)}><img src={certCopyUrl + ".png"} width={200} height={200}></img ></div>
            : <div className="qr-text">QR CODE</div>

        const qrCodeCss = this.props?.issuedOn || invalid ? "qr-code with-image" : "qr-code";

        var kind = "";
        if(this.props.premium)  kind = "Premium ";
        return (

            <div className="certificate-body-wrapper">
                <div className="qr-info">
                    <div className={qrCodeCss}>
                        {certcontent}
                    </div>
                    <div className="qr-details">
                        <div className="qr-title">{kind}Certificate</div>
                        <div className="qr-subtext">{this.props.name ?? "UNNAMED"}</div>

                        <div className="qr-title">Verified</div>
                        <div className="qr-subtext">
                            {this.props?.issuedOn
                                ? <Moment format="DD MMM YYYY">{this.props.issuedOn}</Moment>
                                : "Not Verified"
                            }
                        </div>

                        <div className="qr-title">Expires</div>
                        <div className="qr-subtext">
                            {this.props?.expiresOn
                                ? <Moment format="DD MMM YYYY">{this.props.expiresOn}</Moment>
                                : "N/A"
                            }
                        </div>
                    </div>
                </div>

                {canUse &&
                    <>
                        <br />

                        <div className="qr-title">Use your certificate</div>

                        <div className="qr-use">

                            <div className="qr-link doc-link" onClick={() => window.open(certCopyUrl)}>
                                {certCopyUrl}.png
                            </div>

                            <div className="qr-copy doc-link" onClick={() => this.copyToClipboard(certCopyUrl + ".png")}>
                                <CopyOutlined />
                            </div>

                        </div>
                    </>
                }
            </div>

        );

    }

}